import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby"

/**
 * Category list. Appears on blog homepage and blog category archives.
 */
export default function CategoryList({activeCategory}) {

  //query for blog categories
  const query = useStaticQuery(graphql`
    query {
      allWpCategory(
        sort: {fields: category___order}
      ) {
        nodes {
          name
		  uri
        }
      }
	  wpPage(isPostsPage: {eq: true}) {
	    uri
	  }
    }
  `)

  return(
	<aside className="categories">
		<nav className="internal">
			<ul>
		      {query?.allWpCategory?.nodes?.length && query.allWpCategory.nodes.map((item, i) => {
				//The first category 'featured' always links to the blog homepage
				if (i===0 && query?.wpPage) {
				  return (<li key={`category${i}`}><Link to={query.wpPage.uri} className={!activeCategory ? 'active' : ''}>{item.name}</Link></li>)
				}
				return (<li key={`category${i}`}><Link to={item.uri} className={(activeCategory?.name===item.name) ? 'active' : ''}>{item.name}</Link></li>)
	          })}
		    </ul>
		</nav>
	</aside>
  )
}

