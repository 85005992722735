import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout' 
import Hero from '../components/widgets/Hero' 
import PostPreview from '../components/PostPreview'
import CategoryList from '../components/CategoryList'
import Seo from 'gatsby-plugin-wpgraphql-seo';

/*
 * Category index 
 */
export default function Category({ data }) {
  const category = data.allWpCategory.nodes[0]
  const posts = data.allWpPost;
  
  return (
    <Layout className="category">	
        <Seo post={category} />
		<div className="page-widgets">
			<Hero widget={{layout : 'mini'}} />
		</div>
		<CategoryList activeCategory={category} />
		
		<article className="internal">
		  
          <h1>{category.name}</h1>

		  <div className="articles">
				
        	{posts?.nodes?.length && posts.nodes.map((post, i) => {	
				const exists = post.categories?.nodes?.length && post.categories.nodes.some(c => (c.name === category.name));	
				return exists ? <PostPreview key={`post${i}`} post={post} /> : null;
	        })}
				
		  </div>

		</article>
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
    allWpCategory(filter: { id: { eq: $id } }) {
      nodes {
        name
		seo {
	        title
	        metaDesc
	        focuskw
	        metaKeywords
	        metaRobotsNoindex
	        metaRobotsNofollow
	        opengraphTitle
	        opengraphDescription
	        opengraphImage {
	          altText
	          sourceUrl
	          srcSet
	          localFile {
	            publicURL
	          }
	        }
	        twitterTitle
	        twitterDescription
	        twitterImage {
	          altText
	          sourceUrl
	          srcSet
	          localFile {
	            publicURL
	          }
	        }
	        canonical
	        cornerstone
	        schema {
	          
	          raw
	        }
	      }
      }
    }
	allWpPost(
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
		...PostFragment
      }
    }
  }
`
